<template>
	<div class="typing-practice">
		<h1>打字练习</h1>
		<div class="level-selector">
			<label>
				选择难度：
				<select v-model="level" @change="restart">
					<option value="easy">简单</option>
					<option value="medium">中等</option>
					<option value="hard">困难</option>
				</select>
			</label>
		</div>
		<div class="text-display">
			<span v-for="(char, index) in text" :key="index"
				:class="{ correct: index < currentIndex, incorrect: index === currentIndex && incorrect }">
				{{ char }}
			</span>
		</div>
		<input ref="typingInput" v-model="userInput" @input="handleInput" class="typing-input" placeholder="开始打字..."
			:disabled="finished" />
		<div class="keyboard-layout">
			<div v-for="(row, rowIndex) in keyboard" :key="rowIndex" class="keyboard-row">
				<button v-for="(key, keyIndex) in row" :key="keyIndex" :class="{ active: key === activeKey }">
					<text v-if="key == 'SPACE'" class="on">{{ key }}</text>
					<text v-else-if="key == '中/英'" class="zy">{{ key }}</text>
					<text v-else-if="key == '回车'" class="hc">{{ key }}</text>
					<text v-else-if="key == 'Shift'" class="shift">{{ key }}</text>
					<text v-else>{{ key }}</text>
					<div class="thser"></div>
					<text v-if="key == 'F'" class="F"></text>
					<text v-if="key == 'J'" class="J"></text>
				</button>
			</div>
		</div>
		<div v-if="finished" class="result">
			<p>恭喜你！完成了本次练习。</p>
			<p>请按回车键进行下一句练习。</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				levels: {
					easy: ["abcdefghijklmnopqrstuvwsyz", "cat dog sun", "apple banana orange", "run jump swim", "1 2 3 4 5 6 7 8 9 0"],
					medium: ["The quick brown fox jumps over the lazy dog.", "Typing is a useful skill to learn.",
						"Practice makes perfect."
					],
					hard: ["Sphinx of black quartz, judge my vow!", "How vexingly quick daft zebras jump!",
						"Pack my box with five dozen liquor jugs."
					],
				},
				level: "easy",
				text: "", // 动态生成
				userInput: "",
				currentIndex: 0,
				incorrect: false,
				finished: false,
				keyboard: [
					["`", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "=", "删除"],
					["TAB","Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "[", "]", "、"],
					["中/英","A", "S", "D", "F", "G", "H", "J", "K", "L", ";", ",", "回车"],
					["Shift","Z", "X", "C", "V", "B", "N", "M", ",", ".", "/", "Shift"],
					["fn","control","option","command","SPACE","command","option","👈","↕️","👉"]
				],
				activeKey: "",
			};
		},
		methods: {
			handleInput() {
				const currentChar = this.text[this.currentIndex];
				const inputChar = this.userInput[this.currentIndex];

				if (inputChar === currentChar) {
					this.incorrect = false;
					this.currentIndex++;
					this.updateActiveKey();

					if (this.currentIndex === this.text.length) {
						this.finished = true;
					}
				} else {
					this.incorrect = true;
				}

				// 清除多余的输入
				this.userInput = this.userInput.slice(0, this.currentIndex + 1);
			},
			updateActiveKey() {
				const currentChar = this.text[this.currentIndex]?.toUpperCase() || "";
				this.activeKey = currentChar === " " ? "SPACE" : currentChar;
			},
			restart() {
				this.generateRandomText();
				this.userInput = "";
				this.currentIndex = 0;
				this.incorrect = false;
				this.finished = false;
				this.activeKey = "";
				this.updateActiveKey();
				this.focusInput();
			},
			nextText() {
				if (this.finished) {
					this.finished = false;
					this.restart();
				}
			},
			generateRandomText() {
				const sentences = this.levels[this.level];
				this.text = sentences[Math.floor(Math.random() * sentences.length)];
			},
			focusInput() {
				this.$nextTick(() => {
					this.$refs.typingInput?.focus();
				});
			},
		},
		mounted() {
			document.addEventListener("keyup", (e) => {
				if (e.key === "Enter") {
					this.nextText();
				}
			});
			this.restart();
		},
	};
</script>

<style scoped>
	.typing-practice {
		text-align: center;
		max-width: 1200px;
		margin: 50px auto;
	}

	.level-selector {
		margin-bottom: 20px;
	}

	.text-display {
		font-size: 48px;
		margin: 20px 0;
		letter-spacing: 1px;
	}

	.text-display span {
		padding: 0 2px;
	}

	.text-display .correct {
		color: green;
	}

	.text-display .incorrect {
		color: red;
		text-decoration: underline;
	}

	.typing-input {
		font-size: 18px;
		padding: 10px;
		width: 100%;
		max-width: 600px;
		margin-bottom: 20px;
	}

	.keyboard-layout {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
	}

	.keyboard-row {
		display: flex;
		margin-bottom: 5px;
	}
	.keyboard-row .on {
		display: flex;
		padding-left: 81px;
		padding-right: 81px;
	}
	.keyboard-row .zy{
		display: flex;
		padding-left: 18px;
		padding-right: 18px;
	}
	.keyboard-row .hc{
		display: flex;
		padding-left: 18px;
		padding-right: 18px;
	}
	.keyboard-row .shift{
		display: flex;
		padding-left: 36px;
		padding-right: 36px;
	}
	
	button {
		min-width: 50px;
		height: 50px;
		margin: 10px;
		font-size: 16px;
		cursor: pointer;
		border: 1px solid #ccc;
		background-color: #f9f9f9;
		border-radius: 4px;
		position: relative;
	}
	button .F{
		position: absolute;
		left: 15px;
		top: 35px;
		width: 18px;
		height: 2px;
		background: #0f5132;
	}
	button .J{
		position: absolute;
		left: 15px;
		top: 35px;
		width: 18px;
		height: 2px;
		background: #0f5132;
	}

	button.active {
		background-color: #d1e7dd;
		border-color: #0f5132;
		color: #0f5132;
	}
	button.active .thser{
		position: absolute;
		left: 8px;
		top: 32px;
		width: 40px;
		height: 40px;
		background-image: url('../assets/thier.png');
		background-repeat: no-repeat; /* 不重复 */
		background-size: cover; /* 覆盖整个容器，不变形 */
	}

	.result {
		margin-top: 20px;
	}
</style>